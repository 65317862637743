import types from "../../config/types.js";
import MockServer from "./MockServer.js";
import ServiceData from "Mixins/ServiceData.js";
import MockPagination from "./MockPagination.js";

export default {
  mixins: [MockServer, ServiceData, MockPagination],
  data() {
    return {
      searchTimeOut: null,
      currentApp: 'property-reg',
      filtersData: {},
      pricePopup: {
        value: null,
        propertyId: null,
        propertyAddress: null,
        active: false
      }
    };
  },
  computed: {
    address() {
      return {

      };
    },
    filters() {
      return [
        {
          id: "search",
          type: types.components.input,
          width: 4,
          attr: {
            placeholder: "Поиск",
          },
        },
        {
          id: "app_status",
          type: types.components.select,
          width: 1,
          attr: {
            placeholder: "Статус",
            options: [
              {
                id: 'active',
                label: "Активирован",
              },
              {
                id: 'inactive',
                label: "Не активирован",
              },
            ],
          },
        },
      ];
    },
    tableColumns() {
      return [
        {
          id: "address",
          label: "Адрес",
          onclick: () => {
            this.filtersData.order = 'address';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredAddresses();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'address',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "reg_price",
          label: "Цена (руб.)",
          onclick: () => {
            this.filtersData.order = 'reg_price';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredAddresses();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'address',
          style: {
            minWidth: '130px',
          }
        },
        {
          id: "app_status",
          component: {
            type: "switcher",
            "label-true": "Активирован",
            "label-false": "Не активирован",
          },
          label: "Статус активации",
          onclick: () => {
            this.filtersData.order = 'app_status';
            this.toggleColumnOrderType(this.filtersData.order);
            this.getFilteredAddresses();
          },
          clickable: true,
          orderType: this.filtersData.order_type,
          selected: this.filtersData.order === 'app_status',
          style: {
            minWidth: '263px',
          }
        },
      ];
    },
    tableRows() {
      const data = [];
      
      this.propertyList.forEach((property) => {
        data.push({
          id: property.puid,
          columns: [
            {
              id: "address",
              content: {
                id: property.puid,
                label: property.address,
              },
            },
            {
              id: "reg_price",
              content: {
                id: property.puid,
                label: property.reg_price,
              },
            },
            {
              id: "app_status",
              content: {
                id: property.puid,
                label: property.apps && property.apps.length > 0,
              },
            },
          ],
        });
      });

      return data;
    },
    actions() {
      return {
        selected: [
          {
            id: 1,
            type: types.actions.text,
            label: `Выбрано элементов: ${this.rowsSelected.length}`,
          },
          {
            id: 2,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Отменить выделение",
              icon: "cross",
              handler: () => (this.rowsSelected = []),
            },
          },
          {
            id: 3,
            type: types.actions.button,
            attr: {
              type: "secondary",
              label: "Изменить активацию",
              icon: "check",
              handler: () => this.toggleSelectedProperties(),
            },
          },
          // {
          //   id: 4,
          //   type: types.actions.button,
          //   attr: {
          //     type: "secondary",
          //     colored: "danger",
          //     label: "Удалить строку",
          //     icon: "trash",
          //     handler: () => this.deleteContacts(),
          //   },
          // },
        ],
      };
    },
  },
  methods: {
    onRowClick(row) {
      const priceColumn = row.columns.find(column => column.id === 'reg_price');
      const addressColumn = row.columns.find(column => column.id === 'address');
      this.pricePopup.value = priceColumn?.content?.label ?? 0;
      this.pricePopup.propertyAddress = addressColumn?.content?.label ?? 0;
      this.pricePopup.propertyId = row.id;
      this.pricePopup.active = true;
    },
    onPricePopupClose() {
      this.pricePopup.value = null;
      this.pricePopup.propertyId = null;
      this.pricePopup.propertyAddress = null;
      this.pricePopup.active = false;
    },
    onPricePopupApprove(value) {
        this.$store.dispatch('setIsLoading', true);
        this.pricePopup.value = value;

        this.propertyService.updateProperty(this.pricePopup.propertyId, {
          address: this.pricePopup.propertyAddress,
          reg_price: this.pricePopup.value,
        }).then(response => {
          this.getProperties({
            organizationId: this.currentOrganizationId,
            app: this.currentApp
          }).then(response => {
            this.setContactsPagination();
            this.onPricePopupClose();
            this.$store.dispatch('setIsLoading', false);
          })
        })
    },
    onSwitcherClick({ row, column, currentValue }) {
      this.$store.dispatch('setIsLoading', true);
      this.togglePropertyApp({properties: [{id: row}]}).then(response => {
        this.getProperties({
          organizationId: this.currentOrganizationId,
          app: this.currentApp
        }).then(response => {
          this.setContactsPagination();
          this.$store.dispatch('setIsLoading', false);
        })
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch("setNotification", { text: 'Ошибка! Повторите позже.' });
      });
    },
    toggleColumnOrderType(column) {
      this.filtersData.order_type = this.filtersData.order === column && this.filtersData.order_type === 'asc' ? 'desc': 'asc';
    },
    toggleSelectedProperties() {
      this.$store.dispatch('setIsLoading', true);
      let data = {properties: []}
      this.rowsSelected.forEach(value => {
        data.properties.push({id: value})
      })
      this.propertyService.togglePropertyApp(data).then(response => {
        this.rowsSelected = [];
        this.getProperties({
          organizationId: this.currentOrganizationId,
          app: this.currentApp
        }).then(response => {
          this.setContactsPagination();
          this.$store.dispatch('setIsLoading', false);
        })
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
      })
    },
    togglePropertyApp(propertyId) {
      this.$store.dispatch('setIsLoading', true);
      this.propertyService.togglePropertyApp({properties: [{id: propertyId}]}).then(response => {
        this.getProperties({
          organizationId: this.currentOrganizationId,
          app: this.currentApp
        }).then(response => {
          this.setContactsPagination();
          this.$store.dispatch('setIsLoading', false);
        })
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch("setNotification", { text: 'Ошибка! Повторите позже.' });
      })
    },
    filterAddresses(filters) {
      if (this.searchTimeOut) {
        clearTimeout(this.searchTimeOut)
      }

      this.searchTimeOut = setTimeout(() => {
        this.filtersData = filters;

        this.getFilteredAddresses();
      }, 2000);
    },
    setContactsPagination() {
      this.initPagination(
        this.contactsMeta.current_page,
        this.contactsMeta.total,
        this.contactsMeta.per_page
      );
    },
    getFilteredAddresses() {
      this.filtersData.organizationId = this.currentOrganizationId;
      this.filtersData.app = this.currentApp;

      this.getProperties(this.filtersData).then(response => {
        this.setContactsPagination();
      })
    },
    onSwitchPage(page) {
      this.filtersData.page = page;
      this.getFilteredAddresses();
    },
  },
  mounted() {
    this.$store.dispatch('setIsLoading', true);
    this.getProperties({
      organizationId: this.currentOrganizationId,
      app: this.currentApp
    }).then(response => {
      this.setContactsPagination();
      this.$store.dispatch('setIsLoading', false);
    })
  },
};
