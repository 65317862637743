<template>
  <div class="input-container">
    <input v-model="inputValue" :placeholder="placeholder" class="input" :type="type" @input="toggleClearBtn" @blur="toggleClearBtn" />
    <button class="clear-btn" @click="clearInput" :class="{active: clearBtnActive}">
      <i class="pi pi-times"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: this.modelValue,
      clearBtnActive: false
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    clearInput() {
      this.inputValue = '';
      this.clearBtnActive = false;
    },
    toggleClearBtn() {
      this.clearBtnActive = this.inputValue != '';
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  padding: $space-xs;
  width: 100%;
  border: 1px solid $input-border-color;
  border-radius: $border-radius-xs;
  outline: none;

  &::placeholder {
    color: $input-placeholder-color;
  }
}

.input-container {
  position: relative;

  .clear-btn {
    display: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 35%;
    padding: 0;
    border: 0;
    outline: none;
    font-size: 12px;
    color: #999999;

    &.active {
      display: inline-block;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

</style>
