export default {
    state: {
        gates: [],
        tempGateIds: [],
        gatesMeta: {},
    },
    getters: {
        gates: state => state.gates,
        tempGateIds: state => state.tempGateIds.length > 0 ? state.tempGateIds : JSON.parse(localStorage.getItem('tempGateIds')),
        gatesMeta: state => state.gatesMeta
    },
    mutations: {
        SET_GATES(state, payload) {
            state.gates = payload;
        },
        ADD_NEW_GATE(state, payload) {
            state.gates.push(payload);
        },
        REMOVE_GATES(state, payload) {
            state.gates = state.gates.filter(gate => !payload.includes(gate.cuid));
        },
        SET_TEMP_GATE_IDS(state, payload) {
            state.tempGateIds.push(payload);
            localStorage.setItem('tempGateIds', JSON.stringify(payload))
        },
        DELETE_TEMP_GATE_IDS(state) {
            state.tempGateIds = [];
            localStorage.removeItem('tempGateIds');
        },
        SET_GATES_META(state, payload) {
            state.gatesMeta = payload;
        }
    },
    actions: {
        setGates({commit}, payload) {
            commit('SET_GATES', payload);
        },
        addNewGate({commit}, payload) {
            commit('ADD_NEW_GATE', payload);
        },
        removeGates({commit}, cuid) {
            commit('REMOVE_GATES', cuid);
        },
        setTempGateIds({commit}, payload) {
            commit('SET_TEMP_GATE_IDS', payload);
        },
        deleteTempGateIds({commit}) {
            commit('DELETE_TEMP_GATE_IDS');
        },
        setGatesMeta({commit}, payload) {
            commit('SET_GATES_META', payload);
        }
    }
}