import GateService from "Services/GateService.js";
import PropertyService from "Services/PropertyService.js";
import ServerService from "Services/ServerService.js";
import ContactService from "Services/ContactService.js";
import AuthService from "Services/AuthService.js";

export default {
    data() {
        return {
            gateService: GateService,
            propertyService: PropertyService,
            serverService: ServerService,
            contactService: ContactService,
            authService: AuthService
        }
    },
    methods: {
        getProperties(filters = {}) {
            return new Promise((resolve, reject) => {
                this.propertyService.getProperties(filters).then(response => {
                    if (response.data) {
                      this.$store.dispatch('setProperties', response.data)
                      this.$store.dispatch('setContactsMeta', response.meta ?? {})
                    }

                    resolve(response);
                }).catch((error) => {
                    console.log(error)
                    reject(error);
    
                    if (error.response?.status === 401) {
                        // this.authService.refreshToken(this.$store.getters.refreshToken).then(response => {
                        //     console.log('token resp', response)
                        // }).catch((error) => {
                        //     console.log('token refresh error', error)
                        // })
                        this.$store.dispatch('clearStorage');
                        window.location.reload();
                    }
                })
            })
        },
        getPropertiesWithContacts(string = null) {
            this.propertyService.getPropertiesWithContacts(string).then(response => {
                if (response.data) {
                  this.$store.dispatch('setPropertiesWithContacts', response.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getServers(filters = {}) {
            this.serverService.getServers(filters).then(response => {
                if (response.meta) {
                    console.log('serversMeta', response.meta);
                    this.$store.dispatch('setServersMeta', response.meta)
                }

                if (response.data) {
                  this.$store.dispatch('setServers', response.data)
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        getContactsByOrganization(organizationId, propertyId = null, sipServerId = null) {
            this.contactService.getContactsByOrganization(organizationId, propertyId, sipServerId).then(response => {
                this.$store.dispatch('setContacts', response.data ?? [])
            }).catch((error) => {
                if (error.response && error.response.data) {
                    this.$store.dispatch("setNotification", { text: error.response.data.message ?? 'Error' });
                }
            })
        },
        getContacts(filters = {}) {
            return new Promise((resolve, reject) => {
                this.contactService.getContacts(filters).then(response => {
                    this.$store.dispatch('setContactsMeta', response.data ? response.data : {})
                    this.$store.dispatch('setContacts', response.data && response.data.data ? response.data.data : [])

                    resolve(response);
                }).catch((error) => {
                    if (error.response && error.response.data) {
                        this.$store.dispatch("setNotification", { text: error.response.data.message ?? 'Error' });
                    }

                    resolve(error)
                })
            });
        },
        getVerifiedColumnContent(id, label) {
            let content = {id: id};

            if (label) {
                content['label-true'] = label;
            } else {
                content['label-false'] = label;
            }

            return content;
        },
    },
    computed: {
        gateList() {
            return this.$store.getters.gates;
        },
        gatesMeta() {
            return this.$store.getters.gatesMeta;
        },
        serverList() {
            return this.$store.getters.servers;
        },
        serversMeta() {
            return this.$store.getters.serversMeta;
        },
        propertyList() {
            return this.$store.getters.properties;
        },
        contactList() {
            return this.$store.getters.contacts;
        },
        contactsMeta() {
            return this.$store.getters.contactsMeta;
        },
        propertiesWithContactsList() {
            return this.$store.getters.propertiesWithContacts;
        },
        serverListOptions() {
            let serverListOptions = [];
      
            this.serverList.forEach((server) => {
              serverListOptions.push({
                id: server.suid,
                label: server.host
              });
            });
      
            return serverListOptions;
        },
        propertyListOptions() {
            let propertyListOptions = [];
      
            this.propertyList.forEach((property) => {
              propertyListOptions.push({
                id: property.puid,
                label: property.address
              });
            });
      
            return propertyListOptions;
        },
        gatePropertyListOptions() {
            let gatePropertyList = [];

            this.gateList.forEach(gate => {
                if (gatePropertyList.length > 0) {
                    gatePropertyList.forEach(property => {
                        if (property.address != gate.property.address) {
                            gatePropertyList.push({
                                id: gate.property.puid,
                                label: gate.property.address
                            })
                        }
                    })
                } else {
                    gatePropertyList.push({
                        id: gate.property.puid,
                        label: gate.property.address
                    })
                }
            })

            return gatePropertyList
        },
        currentOrganizationId() {
            return this.$store.getters.currentOrganizationId;
        },
        serverRules() {
            return this.$store.getters.serverRules;
        }
    }
}