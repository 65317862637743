<template>
  <Popup title="Изменить цену" @close="close" @approve="priceApproved">
    <Input v-model="price" type="number" @keydown="keyDown" />
    <small style="color: red" v-if="hasError">Некорректный формат цены</small>
  </Popup>
</template>

<script>
import Popup from "../../components/Popup.vue";
import Input from "../../components/Form/Input.vue";
import ServiceData from "../../mixins/ServiceData.js"

export default {
  name: "Price",
  components: { Popup, Input },
  mixins: [ServiceData],
  data() {
    return {
      price: null,
      hasError: false,
    }
  },
  props: {
    value: {
      type: Number,
      required: true
    },
  },
  mounted() {
    this.price = this.value
  },
  methods: {
    close() {
      this.$emit("close");
    },
    priceApproved() {
      if (parseInt(this.price) <= 0) {
        this.hasError = true;
        return;
      }

      this.hasError = false;
      this.$emit("approved", parseInt(this.price));
    },
    keyDown(e) {
      return ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
    }
  }
};
</script>

<style lang="scss" scoped></style>
