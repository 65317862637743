const server = 'https://property-reg-api.dev.space-smart.ru';
// const server = 'http://localhost:5001';

export default {
    server: server,
    apiHost: `${server}/api`,
    condoUserId: 'dd15eba4-73fa-4bac-8284-49ff7ec717a9',
    userToken: 'k_B8r4CU1OUNgCDpaCmo-fTsnfGYoAFYZ38w1qHt6a1',
    refreshToken: 'FNRV_zqD3N15vGNSC9T2vdHrqMEwKLBeS_PSHBvkgNK',
    condoOrganizationId: '9c2f63c8-9288-4942-b24f-e11da26f6bec',
    currentOrganizationId: '5faeb746-dd9d-49d6-a5ae-3e9ef9dd4e66',
    isProduction: true
}
