<template>
  <div class="wrapper">
    <table class="table">
      <tbody>
        <tr class="table__row table__row_header">
          <td
            v-if="selectable"
            :class="[
              'table__cell table__cell_checkbox',
              {
                table__row_clickable: clickable,
              },
            ]"
          >
            <Checkbox
              v-bind="allRowsCheckboxStatus"
              @click.stop="toggleAllRows()"
            />
          </td>
          <td
            v-for="column in columns"
            :key="`header-column-${column.id}`"
            :class="['table__cell', {
                table__cell_clickable: column.clickable,
                table__cell_selected: column.selected,

            },]"
            @click="column.onclick"
            :style="column.style"
          >
            {{ column.label }}
            <span v-if="column.selected && column.orderType === 'asc'">&#x21BE;</span>
            <span v-else-if="column.selected && column.orderType === 'desc'">&#x21C2;</span>
            <span v-else>&#x21BF;&#x21C2;</span>
          </td>
        </tr>
        <tr
          v-for="row in rows"
          :key="`row-${row.id}`"
          :class="[
            'table__row table__row_body',
            {
              table__row_clickable: clickable,
            },
          ]"
          @click="clickable && $emit('rowClick', row)"
        >
          <td v-if="selectable" :class="`table__cell table__cell_checkbox`">
            <Checkbox
              :selected="modelValue.includes(row.id)"
              @click.stop="toggleSelectedRow(row.id)"
            />
          </td>
          <td
            v-for="column in columns"
            :key="`row-${row.id}-column-${column.id}`"
            :class="`table__cell table__cell_${column.id}`"
          >
            <Switcher
              v-if="column.component?.type === 'switcher'"
              :on="getColumnValue(row.columns, column.id)"
              :id="column.id"
              click-available
              @switcherClick="
                onSwitcherClick(
                  row.id,
                  column.id,
                  getColumnValue(row.columns, column.id)
                )
              "
              @click.stop
            >
              {{
                getColumnValue(row.columns, column.id)
                  ? column.component["label-true"]
                  : column.component["label-false"]
              }}
            </Switcher>
            <template v-else-if="column.component?.type === 'link'">
              <button class="table-btn" @click="openPopup">
                <span>{{ getColumnValue(row.columns, column.id) }}</span>
              </button>
            </template>
            <template v-else>
              {{ getColumnValue(row.columns, column.id) }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Checkbox from "../components/Form/Checkbox.vue";
import Switcher from "../components/Switcher.vue";

export default {
  name: "Table",
  components: { Checkbox, Switcher },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      dafault: [],
    },
    rows: {
      type: Array,
      dafault: [],
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rowsSelected: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    allRowsCheckboxStatus() {
      return {
        selected: this.rowsSelected.length ? true : false,
        partly: this.rowsSelected.length < this.rows.length,
      };
    },
  },
  methods: {
    openPopup(value) {
      console.log('openTest', value)
    },
    getColumnValue(columns, columnId) {
      return columns.find((col) => col.id === columnId)?.content?.label;
    },
    toggleSelectedRow(rowId) {
      const position = this.rowsSelected.indexOf(rowId);
      if (position === -1) return this.rowsSelected.push(rowId);
      this.rowsSelected.splice(position, 1);
    },
    toggleAllRows() {
      if (this.rowsSelected.length) return (this.rowsSelected = []);
      this.rowsSelected = this.rows.map((item) => item.id);
    },
    onSwitcherClick(row, column, currentValue) {
      this.$emit("switcherClick", { row, column, currentValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  border: 1px solid $table-border-color;
  border-radius: $border-radius-md;
  overflow: auto;
}

.table {
  $root: &;

  margin: 0px;

  .table-btn {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;

    span {
      font-weight: 15px;
      padding-bottom: 2px;
      border-bottom: 1px dashed $table-border-color;
    }
  }

  .table-btn:active {
    outline: none;
  }

  &__row {
    &_header {
      #{$root}__cell {
        color: $table-header-row-color;
        font-size: $font-size-xs;
        font-weight: $font-weight-lg;
      }
    }

    &_body {
      &:hover {
        background-color: $table-hovered-row-background-color;
      }
    }

    &_clickable {
      &:hover {
        cursor: pointer;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $table-border-color;
    }
  }

  &__cell {
    padding: $space-md $space-xs;
    border: 0px;
    font-size: $font-size-md;
    white-space: nowrap;

    &_checkbox {
      width: 1%;
    }

    &_address {
      width: 50%;
    }

    &_clickable {
      &:hover {
        cursor: pointer;
      }
    }
    &_selected {
      background-color: $color-grey-2;
    }
  }
}
</style>
