export default {
    state: {
        accessToken: null,
        refreshToken: null,
        condoUserId: null,
        condoOrganizationId: null,
        currentOrganizationId: null,
    },
    getters: {
        accessToken: state => state.accessToken /*?? localStorage.getItem('userToken')*/,
        refreshToken: state => state.refreshToken /*?? localStorage.getItem('refreshToken')*/,
        condoUserId: state => state.condoUserId /*?? localStorage.getItem('condoUserId')*/,
        condoOrganizationId: state => state.condoOrganizationId /*?? localStorage.getItem('condoOrganizationId')*/,
        currentOrganizationId: state => state.currentOrganizationId /*?? localStorage.getItem('currentOrganizationId')*/,
    },
    mutations: {
        SET_ACCESS_TOKEN(state, payload) {
            state.accessToken = payload;
            localStorage.setItem('userToken', payload);
        },
        SET_REFRESH_TOKEN(state, payload) {
            state.refreshToken = payload;
            localStorage.setItem('refreshToken', payload);
        },
        CLEAR_STORAGE(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.condoUserId = null;
            state.condoOrganizationId = null;
            state.currentOrganizationId = null;

            localStorage.clear();
        },
        SET_CONDO_USER_ID(state, payload) {
            state.condoUserId = payload;
            localStorage.setItem('condoUserId', payload);
        },
        SET_CONDO_ORGANIZATION_ID(state, payload) {
            state.condoOrganizationId = payload;
            localStorage.setItem('condoOrganizationId', payload);
        },
        SET_CURRENT_ORGANIZATION_ID(state, payload) {
            state.currentOrganizationId = payload;
            localStorage.setItem('currentOrganizationId', payload);
        }
    },
    actions: {
        setAccessToken({ commit }, payload) {
            commit('SET_ACCESS_TOKEN', payload);
        },
        setRefreshToken({ commit }, payload) {
            commit('SET_REFRESH_TOKEN', payload);
        },
        clearStorage({ commit }) {
            commit('CLEAR_STORAGE');
        },
        setCondoUserId({ commit }, payload) {
            commit('SET_CONDO_USER_ID', payload);
        },
        setCondoOrganizationId({ commit }, payload) {
            commit('SET_CONDO_ORGANIZATION_ID', payload);
        },
        setCurrentOrganizationId({ commit }, payload) {
            commit('SET_CURRENT_ORGANIZATION_ID', payload);
        }
    }
}