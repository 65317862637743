import httpProvider from 'Providers/http.js';

export default {
    getProperties(filters = {}) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/property-reg/property?' + new URLSearchParams(filters).toString()).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getProperty(propertyId) {
        return new Promise((resolve, reject) => {
            httpProvider.get('/property-reg/property/show/' + propertyId).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    updateProperty(propertyId, data) {
        return new Promise((resolve, reject) => {
            httpProvider.put('/property-reg/property/update/' + propertyId, data).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    getPropertiesWithContacts(search = null) {
        let urlParams = search ? '?search=' + search : '';

        return new Promise((resolve, reject) => {
            httpProvider.get('/sigur/property/with-contacts' + urlParams).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    deletePropertyContacts(propertyId) {
        return new Promise((resolve, reject) => {
            httpProvider.delete('/sigur/property/contacts/delete/' + propertyId).then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
    togglePropertyApp(data) {
        return new Promise((resolve, reject) => {
            httpProvider.post('/property-reg/toggle', data)
            .then(response => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            })
        })
    },
}