import Address from "Pages/Address/Index.vue";
import Auth from "Pages/Auth.vue";

import auth from "Middleware/auth.js";
import guest from "Middleware/guest.js";

export default [
  {
    path: "/",
    name: "Auth",
    component: Auth,
    meta: {
      // middleware: [guest]
    }
  },
  {
    path: "/address",
    name: "Address",
    component: Address,
    meta: {
      // middleware: [auth]
    }
  },
];
